/* You can add global styles to this file, and also import other style files */
*, *::before, *::after {
  box-sizing: border-box;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.notify_snackbar {
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-typography-button-font-size: 16px;
  &.error {
    --mdc-snackbar-container-color: red;
    --mdc-snackbar-supporting-text-color: #fff;
    --mat-mdc-snack-bar-button-color: #fff;
  }
  &.success {
    --mdc-snackbar-container-color: limegreen;
    --mdc-snackbar-supporting-text-color: #000;
    --mat-mdc-snack-bar-button-color: #000;
  }
}